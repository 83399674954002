body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  min-height: 55vh;
}

footer {
  border-top: 1px solid #000;
  width:100%;
  padding: 20px 20px;
}

.footer-nav {
  float:right;
}

.align-center
{
  display: block;
  text-align: center;
  margin: 10px;
}

.section {

  margin-top: 30px;

}

.job {
 border-bottom: 1px solid #000;
 display: block;
 width: 100%;
 clear:both;
 padding: 10px 0 20px;
}

.job h3 {
  font-size: 22px;
}

.job .btn {
  float: right;
  display: inline-block;
  width: auto;
  margin-top: -20px;
}

.progress-container {
  text-align: center;
}

.gallery-item {
  max-width: 28%;
  margin: 0px 20px 30px;
  padding: 10px;
  min-height: 200px;
  background: #ccc;
  display: inline-block;
  border-radius: 18px;
  box-shadow: 0px 0px 15px #222;
  text-align: center;
}

.gallery-item .controls {
  padding: 20px 0;
}

.gallery-item .controls input {
  width: 50px;
  padding: 10px;
  margin: 0px 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 19px;
  line-height: 15px;
}

.gallery-item .controls .btn {
  margin-top: -8px ;
  padding: 12px 20px;
}

.gallery-item img {
  height: 120px;
}

.gallery-item span {
  font-size: 19px; 
  font-weight: bold;
  display:block;
}